import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery,graphql, Link } from 'gatsby'

import ChevronDoubleRight from "../static/svg/icon-chevron-double-right.inline.svg";

function BlogCardsCol({ targetSlugs,  ...props }) {

    const data = useStaticQuery(graphql`
    query mdxQuery3 {
        allMdx(sort: {fields: frontmatter___lastmod, order: DESC}) {
            nodes {
              frontmatter {
                title
                lastmod(formatString: "YYYY年M月D日")
                description
                hero_image_alt
                hero_image {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 400
                      transformOptions: {cropFocus: CENTER}
                      aspectRatio: 1
                    )
                  }
                }
                tags
              }
              slug
              id
              
            }
          }
      }
    `);

    const posts = data.allMdx.nodes;

    const filteredData = posts.filter(post => {
        const {slug} = post;
        if ( targetSlugs != null  && Array.isArray(targetSlugs) ) {
        return (targetSlugs.includes(slug)) }
    });

   
    return (
    <div className="flex flex-row flex-wrap">
        {filteredData.map((node) => (
            <article key={`${node.id}`} className="w-1/2 md:w-1/3 ">
                <div className="flex flex-row flex-wrap items-start justify-items-center p-4">


                    <div className="mt-2 mb-1 ml-1 mr-0.5 pt-3">
                        <Link to={`/blog/${node.slug}`}>
                            <GatsbyImage
                                image={node.frontmatter.hero_image.childImageSharp.gatsbyImageData}
                                alt={node.frontmatter.hero_image_alt}
                                placeholder="blurred"
                            />
                        </Link>
                    </div>

                    <div className="pb-2 hover-hover:hover:bg-gray-100 ">
                        <Link to={`/blog/${node.slug}`}>
                            <h2 className="ml-2 mr-0.5 mt-1 mb-0  text-base">{node.frontmatter.title}</h2>

                        </Link>
                        <p className="mt-2 mb-1 mr-2 text-right text-sm underline text-c-main hover-hover:hover:text-c-acc">
                            <Link to={`/blog/${node.slug}`} >

                                続きを読む
                                <ChevronDoubleRight className="h-4 w-4 align-middle inline-block mr-0.5  " />


                            </Link>
                        </p>
                    </div>

                </div>



            </article>

        )
        )}
        
    </div>
    )
};

BlogCardsCol.defaultProps = {
    targetSlugs: null,
  };


export default BlogCardsCol;